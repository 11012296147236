import {
	authCookieNameWithPrefix,
	getFromLocalStorage,
	parseCookie,
	removeFromLocalStorage,
	safeParseJson,
	saveToLocalStorage,
	pickServiceUrl,
	VALID_ROLES,
} from "./utils.js"
import { ref } from "vue"
import _ from "lodash"
import axios from "axios"

const ID_TOKEN_TIMESTAMP_PROPS = ["validUntil", "refreshValidUntil", "issuedAt"]
const MAX_DIFF_BETWEEN_SERVER_CLIENT_TIMESTAMPS = 20 * 60 * 1000
const MIN_TIME_BETWEEN_TIMESTAMPS_NOT_IN_SYNC_WARNINGS = 2 * 60 * 60 * 1000

let mockLoggedInUserRole = undefined
export const getMockLoggedInUserRole = () => {
	if (mockLoggedInUserRole === undefined) {
		const role = new URL(window.location.href).searchParams.get("mockLoggedInUserRole")
		mockLoggedInUserRole = VALID_ROLES.includes(role) ? role : null
	}
	return mockLoggedInUserRole
}

const getIdTokenInfoFromCookies = () => {
	const cookieName = authCookieNameWithPrefix("idTokenInfo")
	const value = parseCookie(document.cookie)[cookieName]
	if (!value) {
		return null
	}
	return safeParseJson(value)
}

const createClientAuth = functionsUrls => {
	const DEFAULT_MAX_RETRIES = 5
	const callCondensFunction = async (name, params, orgId, { maxRetries = DEFAULT_MAX_RETRIES } = {}) => {
		const url = `${pickServiceUrl(functionsUrls, orgId)}/${name}`
		return await callCondensFunctionFromFrontend(url, params, { method: "post", maxRetries })
	}

	let currentIdTokenInfo = null
	const currentLoggedInUserInfo = ref(null)
	const listeners = new Set()

	const loggedInUserInfo = () => currentLoggedInUserInfo.value

	const reloadIdTokenInfoFromCookies = () => {
		const idTokenInfo = Object.freeze(getIdTokenInfoFromCookies())
		if (_.isEqual(currentIdTokenInfo, ID_TOKEN_TIMESTAMP_PROPS)) {
			return
		}
		currentIdTokenInfo = idTokenInfo
		let userInfo = idTokenInfo != null ? Object.freeze(_.omit(currentIdTokenInfo, ID_TOKEN_TIMESTAMP_PROPS)) : null
		const mockLoggedInUserRole = getMockLoggedInUserRole()
		if (
			userInfo != null &&
			mockLoggedInUserRole != null &&
			VALID_ROLES.indexOf(userInfo.role) < VALID_ROLES.indexOf(mockLoggedInUserRole)
		) {
			userInfo = Object.freeze({ ...userInfo, role: mockLoggedInUserRole, notMockRole: userInfo.role })
		}
		if (!_.isEqual(userInfo, currentLoggedInUserInfo.value)) {
			const oldUserInfo = currentLoggedInUserInfo.value
			currentLoggedInUserInfo.value = userInfo
			listeners.forEach(l => l(userInfo, oldUserInfo))
		}
	}
	reloadIdTokenInfoFromCookies()
	setInterval(reloadIdTokenInfoFromCookies, 5_000)

	let lastTimestampsNotInSyncWarning = 0
	const checkUpdatedIdTokenInfo = beforeCallingServerTimestamp => {
		reloadIdTokenInfoFromCookies()
		if (currentIdTokenInfo == null) {
			return
		}
		const ellapsedMs = Date.now() - beforeCallingServerTimestamp
		if (ellapsedMs > 3_000) {
			// to avoid this message showing because of network issues
			return
		}
		const timestampsNotInSync =
			Math.abs(Date.now() - currentIdTokenInfo.issuedAt) > MAX_DIFF_BETWEEN_SERVER_CLIENT_TIMESTAMPS + ellapsedMs
		if (
			timestampsNotInSync &&
			Date.now() > lastTimestampsNotInSyncWarning + MIN_TIME_BETWEEN_TIMESTAMPS_NOT_IN_SYNC_WARNINGS
		) {
			window.alert(
				"Your browser or system time is incorrect. This could lead to issues with using Condens. Please correctly set your time and reload Condens."
			)
			lastTimestampsNotInSyncWarning = Date.now()
		}
	}

	const executeRefresh = async ({ maxRetries = DEFAULT_MAX_RETRIES } = {}) => {
		reloadIdTokenInfoFromCookies()
		const { issuedAt, userSessionId } = currentIdTokenInfo ?? {}
		const beforeCallingServer = Date.now()
		await new Promise(r => setTimeout(r, Math.random() * 200))
		for (const __ of _.range(100)) {
			const pending = getFromLocalStorage("condensTokenRefreshPending")
			if (pending == null || pending < Date.now() - 2_000) {
				break
			}
			await new Promise(r => setTimeout(r, 20))
		}
		reloadIdTokenInfoFromCookies()
		if (currentIdTokenInfo == null) {
			await logout()
			throw Error("executeRefresh called without currentIdTokenInfo, logging out")
		}
		if (userSessionId !== currentIdTokenInfo.userSessionId || issuedAt !== currentIdTokenInfo.issuedAt) {
			console.info("concurrent refresh happened, so we don't need to refresh")
			return
		}
		const now = Date.now()
		saveToLocalStorage("condensTokenRefreshPending", now)
		try {
			await callCondensFunction("refreshIdToken", {}, currentIdTokenInfo.orgId, { maxRetries })
			removeFromLocalStorage("condensTokenRefreshPending")
		} catch (e) {
			if (e.reason !== "network_error") {
				await logout()
			}
			throw e
		}
		checkUpdatedIdTokenInfo(beforeCallingServer)
	}

	const refreshIdToken = async ({ maxAge = 0 } = {}) => {
		reloadIdTokenInfoFromCookies()
		if (currentIdTokenInfo == null) {
			throw Error(`idTokenInfo cookie not set`)
		}
		if (maxAge > 0 && currentIdTokenInfo.issuedAt > Date.now() - maxAge) {
			return
		}
		await executeRefresh()
	}

	const ensureIdTokenNotExpiredIfLoggedIn = async ({ maxRetries = DEFAULT_MAX_RETRIES } = {}) => {
		reloadIdTokenInfoFromCookies()
		if (currentIdTokenInfo == null) {
			return
		}
		const MIN_REMAINING_TIME = 5 * 60 * 1000
		if (currentIdTokenInfo.validUntil > Date.now() + MIN_REMAINING_TIME) {
			return
		}
		try {
			await executeRefresh({ maxRetries })
		} catch (e) {
			if (e.reason !== "network_error") {
				console.error(e)
			}
		}
	}

	const refreshIdTokenIfLoggedIn = async () => {
		reloadIdTokenInfoFromCookies()
		const maxAge = 2_000
		if (currentIdTokenInfo == null || currentIdTokenInfo.issuedAt > Date.now() - maxAge) {
			return
		}
		try {
			await executeRefresh()
		} catch (e) {
			if (e.reason !== "network_error") {
				console.error(e)
			}
		}
	}

	const loginWithPassword = async (orgId, email, password, { totpToken = null } = {}) => {
		const beforeCallingServer = Date.now()
		await callCondensFunction(
			"loginWithPassword",
			{
				orgId,
				email,
				password,
				...(totpToken != null ? { totpToken } : {}),
			},
			orgId
		)
		checkUpdatedIdTokenInfo(beforeCallingServer)
	}

	const logout = async () => {
		reloadIdTokenInfoFromCookies()
		if (!isLoggedIn()) {
			return
		}
		await callCondensFunction("logout", {}, currentIdTokenInfo.orgId)
		reloadIdTokenInfoFromCookies()
		if (isLoggedIn()) {
			console.error(`Still logged in after logout`)
		}
	}

	const addLoggedInUserInfoListener = l => {
		listeners.add(l)
		return () => listeners.delete(l)
	}

	const isLoggedIn = () => currentLoggedInUserInfo.value != null

	return {
		isLoggedIn,
		loggedInUserInfo,
		addLoggedInUserInfoListener,

		refreshIdToken,
		ensureIdTokenNotExpiredIfLoggedIn,
		refreshIdTokenIfLoggedIn,
		loginWithPassword,
		logout,
		reloadIdTokenInfoFromCookies,
	}
}

export let clientAuth = createClientAuth({})

export const initClientAuth = functionsUrls => {
	clientAuth = createClientAuth(functionsUrls)
}

export const callCondensFunctionFromFrontend = async (
	url,
	params = {},
	{ queryParams = {}, method = "get", maxRetries = 0, fallbackToNull = false } = {}
) => {
	let failReason = "unknown"
	for (const __ of _.range(maxRetries + 1)) {
		try {
			let result = null
			if (method === "get") {
				result = await axios.get(url, { params: { ...queryParams, ...params }, withCredentials: true })
			} else if (method === "post") {
				result = await axios.post(url, params, { params: queryParams, withCredentials: true })
			}
			return result.data
		} catch (e) {
			failReason = e.response?.data?.reason ?? "unknown"
			if (e.response == null || e.code === "ERR_NETWORK") {
				failReason = "network_error"
			}
			if (failReason !== "network_error") {
				break
			}
		}
		await new Promise(r => setTimeout(r, (1 + Math.random() * 0.3) * 2000))
	}
	if (!fallbackToNull) {
		throw { reason: failReason }
	} else {
		return null
	}
}
