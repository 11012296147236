<template>
	<div>
		<h1>
			{{ orgId }}
		</h1>
		<div class="main-notification" v-if="org.deleteVersion != null">DELETED</div>
		<div class="main-notification" v-else-if="pauseSummary != null">
			{{ pauseSummary }}
		</div>
		<h3>
			Plan:
			<template v-if="org.freeAccessReason == null">{{ org.plan }}</template>
			<template v-else>free ({{ org.freeAccessReason }})</template> |
			<template v-if="org.seats != null">
				Seats: {{ org.seats }}
				<span :style="{ color: usedSeats > org.seats ? 'darkred' : 'darkgreen' }">
					({{ usedSeats }} used)
				</span>
			</template>
		</h3>
		<div>
			<b>Users</b>
			<br />
			Active: {{ activeUsersInOrg.length }} | Active by role:
			<span v-for="({ role, count }, index) of activeCountByRole" :key="role">
				{{ role }} {{ count }}<template v-if="index < activeCountByRole.length - 1">,</template>
			</span>
			| Invitation pending:
			{{ usersInOrg.filter(u => u.invitationPending === true).length }} | Inactive:
			{{ usersInOrg.filter(u => !u.active).length }}
		</div>

		<div class="org-edit">
			<div v-if="org.plan == 'trial'">
				Days remaining:
				<input
					v-model="editableTrialDaysRemaining"
					type="number"
					class="text-input"
					style="width: 80px"
				/>
				<button @click="updateTrialDuration" :disabled="updateInProgress">update</button><br />
				Trial duration: {{ trialDuration }}<br />
				Trial ends at
				{{ new Date(org.activated + trialDuration * 24 * 60 * 60 * 1000).toUTCString() }}
			</div>
			<div>
				<div v-for="flag of orgFlags" :key="flag">
					{{ flag }}
					<input
						type="checkbox"
						:checked="org[flag] === true"
						:disabled="updateInProgress"
						@input="toggleOrgFlag(flag)"
					/>
				</div>
				<div v-if="org.disableGenerativeAi">GenAI disabled</div>
				<div v-if="org.ssoConfig != null">
					sso: https://app.condens.io/o/{{ org.ssoConfig.urlName }}
					<template v-if="org.ssoConfig.autoProvisionRole != null">
						, autoProvisionRole: {{ org.ssoConfig.autoProvisionRole }}
					</template>
					<template v-else>, no auto-provisioning</template>
				</div>
			</div>
			<div v-if="org.freeAccessReason != null">
				Free access ({{ org.freeAccessReason }})<br />
				Days remaining:
				<input v-model="freeAccessRemainingDays" placeholder="Months to add" size="5" />
				<button @click="updateFreeAccessRemainingDays" :disabled="updateInProgress">Update</button>
			</div>

			<div>
				<template v-if="org.freeAccessReason == null">
					<div v-if="org.subscriptionId != null">
						<b>Managed by Chargebee. Only change if changed there.</b>
					</div>
					Plan
					<select :value="maybeEditedPlan" @input="editedPlan = $event.target.value">
						<option
							v-for="plan of plans"
							:key="plan"
							:value="plan"
							:disabled="legacyPlans.includes(plan)"
						>
							{{ plan }}
						</option>
					</select>
					<br />
					Billing interval
					<select
						:value="maybeEditedBillingInterval"
						@input="editedBillingInterval = $event.target.value"
					>
						<option v-for="interval of billingIntervals" :key="interval" :value="interval">
							{{ interval }}
						</option>
					</select>
					<br />
					<template v-if="maybeEditedPlan !== 'trial'">
						seats
						<input
							:value="String(editedSeats)"
							@input="editedSeats = Number.parseInt($event.target.value)"
							placeholder="seats"
							type="number"
							style="width: 50px"
						/>
						<br />
					</template>

					<button
						@click="savePlanAndSeats"
						:disabled="
							updateInProgress ||
							(maybeEditedPlan === org.plan &&
								maybeEditedBillingInterval === org.billingInterval &&
								(editedSeats == null || editedSeats === org.seats || editedPlan === 'trial'))
						"
					>
						update plan & seats
					</button>
					<br />
					<br />
					<template v-if="org.plan !== 'trial'">
						Chargebee subscription ID: {{ org.subscriptionId || "No subscription id" }}
						<button @click="updateSubscriptionId" :disabled="updateInProgress">update</button>
						<div v-if="org.paused == null">
							<button
								v-if="org.subscriptionId != null"
								@click="pauseSubscription"
								:disabled="updateInProgress"
							>
								Pause / sync pause date from Chargebee if paused there
							</button>
							<template v-else>
								<button @click="overwritePauseDate" :disabled="updateInProgress">
									Pause (you can pick the pause date)
								</button>
								(only use if also paused/canceled in Chargebee)
							</template>
						</div>
						<div v-else>
							<button @click="unpause" :disabled="updateInProgress">Upause in Condens</button>
							(this will not affect chargebee, so you need to unpause it there too)
						</div>
					</template>
					<br />
				</template>

				<div v-for="{ addon, included } of addons" :key="addon">
					{{ addon }} addon: {{ included ? "enabled" : "disabled" }}
					<button :disabled="updateInProgress" @click="toggleAddon(addon)">
						{{ included ? "disable" : "enable" }}
					</button>
				</div>
			</div>
			<div>
				<router-link :to="{ name: 'usageStatistics', params: { orgId } }">
					Usage metrics </router-link
				><br /><br />
				<b>Reports</b><br />
				<div v-for="{ name, methodName } of reportSpecs" :key="methodName">
					<button @click="downloadReport(methodName)">
						{{ name }}
					</button>
				</div>
			</div>
			<div>
				<button @click="importExampleProjects">Import example projects</button><br />
				<button @click="deleteOrg">⚠️ Delete org 💀</button>
			</div>
		</div>
		<select v-model="workspaceId">
			<option v-for="workspace of workspaces" :key="workspace.id" :value="workspace.id">
				{{ workspace.name }} ({{ workspace.id }})
			</option>
		</select>
		<template v-if="workspaceId != null">
			<div v-if="workspaceStatistics != null">
				Projects: {{ workspaceStatistics.projectsCount }}, Artifacts:
				{{ workspaceStatistics.artifactsCount }}, Published Artifacts:
				{{ workspaceStatistics.publishedArtifactsCount }}
			</div>
			<div>{{ workspaceSettingsSummary }}</div>
			<p v-if="!reportLoading">
				<button @click="loadWorkspaceReport">Load workspace report</button>
			</p>
			<div v-if="reportLoading">Loading report...</div>
			<template v-if="report != null">
				<h2>Global statistics</h2>
				<div>
					Global tags {{ report.globalTags }} | Global tag groups {{ report.globalTagGroups }}
				</div>
				<div>Participants: {{ report.participants }}</div>
				<div>
					Meta info fields: projects {{ report.projectInformationFields }} | participants
					{{ report.participantInformationFields }} | artifacts
					{{ report.artifactInformationFields }}
				</div>
				<h2>Projects</h2>
				<div v-for="project of projects" :key="project.id">
					<h3>({{ project.id }})</h3>
					<div v-if="project.deleted">DELETED</div>
					<div>Type: {{ project.type }}</div>
					<div>created: {{ new Date(project.created).toLocaleDateString() }}</div>
					<div>
						{{ project.researchSessions }} sessions ({{ project.realResearchSessions }} real),
						{{ project.transcriptions }} transcriptions, {{ project.tags }} project tags,
						{{ project.tagGroups }} tag groups, {{ project.highlights }} highlights,
						{{ project.mediaHighlights }} media highlights,
						{{ project.usedProjectTags }}
						project tags used, {{ project.usedGlobalTags }} global tags used,
						{{ project.metaInfoFields }} meta info fields, {{ project.clusters }} clusters,
						{{ project.embeddedHighlights }} embedded highlights,
						{{ project.embeddedArtifacts }} embedded artifacts {{ project.images }} images,
						{{ project.imagesInNotes }} images in notes, {{ project.audios }} audios,
						{{ project.videos }} videos, {{ project.otherFiles }} other files
					</div>
					<div>
						{{ project.descriptionWords }} words in description,
						{{ project.researchSessionWords }} words in sessions, {{ project.artifactWords }} words
						in artifacts, {{ project.artifacts }} artifacts
					</div>
				</div>
			</template>
		</template>
		<hr />
		<h2>Sessions</h2>
		<SessionsTable :groupedSessions="groupedSessions" />
		<hr />
		<h2>Users</h2>
		<div :class="$style.users">
			<div :class="$style.header">Role</div>
			<div :class="$style.header">Status</div>
			<div :class="$style.header">UID</div>
			<div :class="$style.header">Email</div>
			<div :class="$style.header">Name</div>
			<div :class="$style.header">Workspaces</div>
			<div :class="$style.header">Last active</div>
			<template v-for="user of usersInOrg" :key="user.id">
				<div>{{ user.frontendRole || user.role }}</div>
				<div>
					<template v-if="user.invitationPending">pending</template>
					<template v-else-if="!user.active">inactive</template>
					<template v-else>active</template>
				</div>
				<div>{{ user.id }}</div>
				<div>{{ user.email }}</div>
				<div>{{ user.name }}</div>
				<div>{{ (user.workspaceIds || []).join(", ") }}</div>
				<div>{{ lastSessionToStringByUid(user.id) }}</div>
			</template>
		</div>
		<hr />
		<h2>Integrations</h2>
		<div v-for="[key, values] of Object.entries(integrationsReport)" :key="key">
			<b>{{ key }}: </b>{{ values }}
		</div>
	</div>
</template>

<script>
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import SessionsTable from "@/components/SessionsTable.vue"
import { splitUpAndGroupSessions, callCondensFunction, downloadFromCloudFunction } from "@/utils.js"
import _ from "lodash"
import { collections, orgById, startOrRefreshCollectionsSync } from "@/collections"
import { toRelativeTimeString } from "@/utils.js"
import { subYears, lastDayOfMonth } from "date-fns"
import {
	FEATURE_SET_BY_PLAN,
	mergeOp,
	UNPAID_INVOICES_GRACE_PERIOD_MS,
	VALID_PLANS,
} from "@/shared_stuff/utils"
import { getPricingConfig } from "@/shared_stuff/pricing_config"

const rolesOrder = [
	"admin",
	"admin#nonresearch",
	"researcher",
	"full_access_stakeholder",
	"stakeholder",
]

export default {
	computed: {
		org() {
			return orgById(this.orgId)
		},
		workspaces() {
			return _.sortBy(
				Object.values(collections.workspaces).filter(w => w.orgId === this.orgId),
				"id"
			)
		},
		usersInOrg() {
			return _.sortBy(
				Object.values(collections.users).filter(u => u.orgId === this.orgId),
				[
					i => rolesOrder.indexOf(i.frontendRole ?? i.role),
					i => (i.invitationPending ? 1 : 0),
					"email",
				]
			)
		},
		activeUsersInOrg() {
			return this.usersInOrg.filter(u => u.invitationPending !== true && u.active)
		},
		usedSeats() {
			return this.activeUsersInOrg.filter(
				u =>
					(u.role === "admin" || (u.role === "researcher" && u.workspaceIds?.length > 0)) &&
					u.frontendRole !== "admin#nonresearch"
			).length
		},
		activeCountByRole() {
			const result = []
			for (const role of rolesOrder) {
				const count = this.activeUsersInOrg.filter(u => u.role === role).length
				if (count === 0) {
					continue
				}
				result.push({ role, count })
			}
			return result
		},
		trialDuration() {
			if (this.org.trialDuration == null) {
				return 15
			}
			return this.org.trialDuration
		},
		trialDaysRemaining() {
			if (this.org.activated == null || this.org.plan !== "trial") {
				return null
			}
			const DAY_MS = 24 * 60 * 60 * 1000
			const diffMs = new Date().getTime() - this.org.activated
			const diffDays = Math.floor(diffMs / DAY_MS)
			return this.trialDuration - diffDays
		},

		groupedSessions() {
			return splitUpAndGroupSessions(this.webappAnalytics)
		},
		lastSessionToStringByUid() {
			const groupedSessions = this.groupedSessions
			return uid => {
				const uidWithSessions = groupedSessions.find(s => s.uid === uid)
				if (uidWithSessions == null) {
					return ""
				}
				return toRelativeTimeString(uidWithSessions.sessions[0])
			}
		},
		orgLevelIntegrations() {
			return Object.keys(this.org?.integrations || []).join(", ")
		},
		workspaceSettingsSummary() {
			const workspace = this.workspaces.find(w => w.id === this.workspaceId)
			const SETTINGS_PROPS = ["limitInviteByResearchersToRoles", "readonlyAccessEnabled"]
			const activeSettings = _.intersection(Object.keys(workspace ?? {}), SETTINGS_PROPS)
			return `Settings: ${activeSettings.join(", ")}`
		},
		projects() {
			if (this.report == null) {
				return []
			}
			return _.sortBy(
				this.report.projects.filter(p => !p.deleted),
				[i => (i.type === "real" ? 0 : 1), i => i.created]
			)
		},
		pauseSummary() {
			if (this.org.paused != null) {
				const text = this.org.paused < Date.now() ? "Is paused since" : "Will pause on"
				return `${text} ${new Date(this.org.paused).toLocaleString()}`
			}
			if (this.org.unpaidInvoicesSince != null) {
				const willPause = this.org.unpaidInvoicesSince + UNPAID_INVOICES_GRACE_PERIOD_MS
				const text =
					willPause < Date.now()
						? "Paused because of unpaid invoices since"
						: "Will pause because of unpaid invoices on"
				return `${text} ${new Date(willPause).toLocaleString()}`
			}
			return null
		},
		maybeEditedPlan() {
			return this.editedPlan ?? this.org.plan
		},
		maybeEditedBillingInterval() {
			return this.editedBillingInterval ?? this.org.billingInterval
		},
		billingIntervals() {
			if (
				this.maybeEditedPlan === "enterprise_2021" ||
				(this.maybeEditedPlan === "business_2023" && this.org.allowBusinessMonthly !== true)
			) {
				return ["yearly"]
			}
			return ["monthly", "yearly"]
		},
		addons() {
			if (this.org == null || this.org.freeAccessReason != null) {
				return []
			}
			return Object.keys(
				getPricingConfig({ allowBusinessMonthly: this.org.allowBusinessMonthly })[this.org.plan]
					?.addons ?? {}
			).map(addon => ({
				addon,
				included: this.org.addons?.includes(addon),
			}))
		},
	},
	data() {
		this.reportSpecs = [
			{
				name: "Usage for last month",
				methodName: "loadLastMonthUsageStatistics",
			},
			{
				name: "Usage for specific period",
				methodName: "loadUsageStatistics",
			},
			{
				name: "Last logins",
				methodName: "loadLastLoginStatistics",
			},
			{
				name: "Active Contributors by month",
				methodName: "loadActiveResearchersByMonth",
			},
			{
				name: "Active Viewers by month",
				methodName: "loadActiveStakeholdersByMonth",
			},
			{
				name: "Created projects, sessions etc. by month",
				methodName: "loadCreatedItemsByMonth",
			},
		]
		this.plans = [
			"trial",
			..._.sortBy(VALID_PLANS, p => {
				const i = Object.keys(FEATURE_SET_BY_PLAN).indexOf(p)
				return i != -1 ? i : Infinity
			}),
		]
		this.legacyPlans = []
		return {
			webappAnalytics: [],
			report: null,
			integrationsReport: {},
			reportLoading: false,
			workspaceId: null,
			workspaceStatistics: null,
			editableTrialDaysRemaining: 0,
			updateInProgress: false,
			editedPlan: null,
			editedBillingInterval: null,
			editedSeats: null,
			freeAccessRemainingDays: null,
			orgFlags: [
				"unlimitedTranscriptionInTrial",
				"allowMultipleWorkspaces",
				"allowTranscriptionTranslation",
				"allowBusinessMonthly",
			],
		}
	},
	props: ["orgId"],
	methods: {
		async callCondensFunction(name, params = {}, options = undefined) {
			return await callCondensFunction(name, params, {
				...options,
				pickRegionFor: this.orgId,
			})
		},
		downloadFromCloudFunction(name, params = {}) {
			downloadFromCloudFunction(name, params, {
				pickRegionFor: this.orgId,
			})
		},
		async updateTrialDuration() {
			const v = parseInt(this.editableTrialDaysRemaining)
			if (_.isNaN(v) || v < 0) {
				window.alert("Wrong days remaining")
				return
			}
			const trialDuration = this.trialDuration + (v - this.trialDaysRemaining)
			await this.updateOrg({ trialDuration })
		},
		toggleOrgFlag(flag) {
			this.updateOrg({ [flag]: this.org[flag] ? null : true })
		},
		async updateOrg(update) {
			this.updateInProgress = true
			try {
				await this.callCondensFunction(
					"internal/updateOrg",
					{ orgId: this.orgId, update },
					{ method: "post" }
				)
				startOrRefreshCollectionsSync()
			} catch (e) {
				window.alert(`Org update failed (${e.response?.data?.reason ?? "unkown"})`)
			}
			this.updateInProgress = false
		},
		async pauseSubscription() {
			this.updateInProgress = true
			try {
				await this.callCondensFunction(
					"internal/pauseSubscription",
					{ orgId: this.orgId },
					{ method: "post" }
				)
				startOrRefreshCollectionsSync()
			} catch (e) {}
			this.updateInProgress = false
		},
		async loadWorkspaceReport() {
			const workspaceId = this.workspaceId
			this.reportLoading = true
			this.report = null
			const result = await this.callCondensFunction("internal/workspaceReport", { workspaceId })
			if (workspaceId !== this.workspaceId) {
				return
			}
			this.reportLoading = false
			this.report = result
			console.log(result)
		},
		async savePlanAndSeats() {
			if (this.org.subscriptionId != null) {
				const confirmText = "chargebee is updated"
				const input = window.prompt(
					`This subscription is managed by Chargebee and should only be updated if the chargbee subscription has also been updated.\nPlease enter "${confirmText}" to confirm.`
				)
				if (input != "chargebee is updated") {
					window.alert("aborted")
					return
				}
			}
			const update = {}
			if (this.editedSeats != null) {
				update.seats = this.editedSeats
			}
			if (this.editedPlan != null) {
				update.plan = this.editedPlan
			}
			if (this.editedBillingInterval != null) {
				update.billingInterval = this.editedBillingInterval
			}
			if (_.isEmpty(update)) {
				return
			}
			await this.updateOrg(update)
		},
		async loadUsageStatistics() {
			const from = window.prompt("From date (format YYYY-MM-DD)")
			const to = window.prompt(
				"To date (format YYYY-MM-DD)",
				new Date().toISOString().substring(0, 10)
			)
			this.downloadFromCloudFunction("internal/usageStatistics", {
				from,
				to,
				orgId: this.orgId,
			})
		},
		async loadLastMonthUsageStatistics() {
			const lastMonth = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000)
			const from = lastMonth.toISOString().substring(0, 8) + "01"
			const to = new Date(lastDayOfMonth(lastMonth).getTime() + 36 * 60 * 60 * 1000)
				.toISOString()
				.substring(0, 10)
			this.downloadFromCloudFunction("internal/usageStatistics", {
				from,
				to,
				orgId: this.orgId,
			})
		},
		async loadLastLoginStatistics() {
			this.downloadFromCloudFunction("internal/lastLoginStatistics", {
				orgId: this.orgId,
			})
		},
		async loadActiveResearchersByMonth() {
			this.downloadFromCloudFunction("internal/activeResearchersByMonth", {
				orgId: this.orgId,
			})
		},
		async loadActiveStakeholdersByMonth() {
			this.downloadFromCloudFunction("internal/activeStakeholdersByMonth", {
				orgId: this.orgId,
			})
		},
		async loadCreatedItemsByMonth() {
			this.downloadFromCloudFunction("internal/createdItemsByMonth", {
				orgId: this.orgId,
			})
		},
		async enableMagazineBeta() {
			this.updateInProgress = true
			try {
				await this.callCondensFunction(
					"internal/enableMagazineBeta",
					{ orgId: this.orgId },
					{ method: "post" }
				)
				startOrRefreshCollectionsSync()
			} catch (e) {
				window.alert(`Org update failed (${e.response?.data?.reason ?? "unkown"})`)
			}
			this.updateInProgress = false
		},
		async disableMagazineBeta() {
			this.updateInProgress = true
			try {
				await this.callCondensFunction(
					"internal/disableMagazineBeta",
					{ orgId: this.orgId },
					{ method: "post" }
				)
				startOrRefreshCollectionsSync()
			} catch (e) {
				window.alert(`Org update failed (${e.response?.data?.reason ?? "unkown"})`)
			}
			this.updateInProgress = false
		},
		async importExampleProjects() {
			const confirmText = `import example`
			const input = window.prompt(
				`Importing example projects\nPlease enter "${confirmText}" to confirm.`
			)
			if (input !== confirmText) {
				window.alert("Deleting org aborted")
				return
			}
			this.callCondensFunction(
				"internal/importExampleProjects",
				{ workspaceId: this.workspaceId },
				{ method: "post" }
			)
		},
		async deleteOrg() {
			const confirmText = `delete org ${this.orgId}`
			const input = window.prompt(`💀💀 Deleting org\nPlease enter "${confirmText}" to confirm.`)
			if (input !== confirmText) {
				window.alert("Deleting org aborted")
				return
			}
			if (this.org.plan !== "trial") {
				const confirmText = `delete ${this.orgId} with plan ${this.org.plan}`
				const input = window.prompt(
					`💰💸 The org ${this.orgId} has a subscription of type "${this.org.plan}" ⚠️⚠️.\nAre you sure you want to delete it? If yes, please type "${confirmText}" to confirm.`
				)
				if (input !== confirmText) {
					window.alert("Deleting org aborted")
					return
				}
			}
			this.callCondensFunction("internal/deleteOrg", { orgId: this.orgId }, { method: "post" })
			window.alert(`Org ${this.orgId} deleted`)
			this.$router.replace("/")
		},
		async updateFreeAccessRemainingDays() {
			await this.updateOrg({
				paused: Date.now() + this.freeAccessRemainingDays * 24 * 60 * 60 * 1000,
			})
		},
		downloadReport(methodName) {
			this[methodName]()
		},
		async toggleAddon(addon) {
			let addons = this.org.addons ?? []
			let confirmation = `Are you sure you want to ${
				addons.includes(addon) ? "remove" : "add"
			} the addon ${addon}?`
			if (this.org.subscriptionId != null) {
				confirmation += `\n!!! Make sure the addons selected here match the addons in Chargebee !!!`
			}
			if (!window.confirm(confirmation)) {
				return
			}
			if (addons.includes(addon)) {
				addons = _.without(addons, addon)
			} else {
				addons.push(addon)
			}
			await this.updateOrg({
				addons: addons.length > 0 ? addons : mergeOp("delete"),
			})
		},
		async updateSubscriptionId() {
			const subscriptionId = window.prompt("Enter new subscription ID (copied from Chargebee)")
			if (!subscriptionId?.trim()) {
				return
			}
			await this.updateOrg({ subscriptionId })
		},
		unpause() {
			this.updateOrg({ paused: mergeOp("delete") })
		},
		overwritePauseDate() {
			const r = window.prompt(
				`Are you sure you want to manually set the pause date?\nThis will NOT pause the subscription in chargebee, it will only change what the user sees in Condens!!\nType "i know" to confirm.`
			)
			if (r?.toLowerCase() !== "i know") {
				return
			}
			const date = window.prompt(
				`Type in the date when the subscription should be paused in the format year-month-day (so today is ${new Date()
					.toISOString()
					.substring(0, 10)})`,
				new Date().toISOString().substring(0, 10)
			)
			if (!date?.trim()) {
				return
			}
			let d = null
			try {
				d = new Date(date)
			} catch (e) {
				window.alert("Invalid date, aborting")
				return
			}
			this.updateOrg({ paused: d.getTime() })
		},
	},
	async created() {
		this.integrationsReport = await this.callCondensFunction("internal/integrationsReport", {
			orgId: this.orgId,
		})
	},
	components: { SessionsTable },
	watch: {
		orgId: {
			handler() {
				this.unsubAnalytics?.()
				this.unsubAnalytics = firebase
					.firestore()
					.collection("webappAnalytics")
					.where("updated", ">", subYears(new Date(), 1).getTime())
					.where("orgId", "==", this.orgId)
					.onSnapshot(s => {
						this.webappAnalytics = s.docs.map(d => Object.freeze({ id: d.id, ...d.data() }))
					})
			},
			immediate: true,
		},
		workspaces: {
			handler(v) {
				if (
					this.workspace == null ||
					(this.workspaceId !== this.orgId && !this.workspaceId.startsWith(`${this.orgId}#`))
				) {
					this.workspaceId = v[0]?.id
				}
			},
			immediate: true,
		},
		workspaceId: {
			async handler(workspaceId) {
				if (this.org.deleteVersion != null || workspaceId == null) {
					return
				}
				this.report = null
				this.workspaceStatistics = null
				const statistics = await this.callCondensFunction("internal/workspaceStatistics", {
					workspaceId,
				})
				if (this.workspaceId === workspaceId) {
					this.workspaceStatistics = statistics
				}
			},
			immediate: true,
		},
		trialDaysRemaining: {
			handler(v) {
				this.editableTrialDaysRemaining = v
			},
			immediate: true,
		},
		org: {
			handler(plan) {
				if (this.org.seats == null) {
					this.editedSeats = 5
				} else {
					this.editedSeats = this.org.seats
				}
				if (this.org.freeAccessReason != null && this.org.paused != null) {
					this.freeAccessRemainingDays = Math.round(
						(this.org.paused - Date.now()) / (24 * 60 * 60 * 1000)
					)
				}
			},
			immediate: true,
		},
	},
}
</script>

<style lang="scss" module>
.users {
	display: grid;
	grid-template-columns: 150px 150px 1fr 1fr 1fr 1fr 1fr;
	grid-row-gap: 15px;
	grid-column-gap: 15px;
}

.header {
	font-weight: bold;
}
</style>

<style lang="scss" scoped>
.org-edit {
	display: flex;
	margin: 12px 0;
	> div {
		background-color: #e8edf1;
		border-radius: 9px;
		padding: 9px;
	}
	> :not(:last-child) {
		margin-right: 12px;
	}
}

.text-input {
	margin-right: 12px;
}

.main-notification {
	color: red;
	font-size: 19px;
	font-weight: 700;
}
</style>
