<template>
	<div class="metrics-dashboard">
		<h1>Metrics for {{ orgId }}</h1>
		<template v-if="months.length > 0">
			<table>
				<tr>
					<th></th>
					<th v-for="month of months" :key="month">{{ month }}</th>
					<th>Average</th>
				</tr>
				<tr v-for="metric of metrics" :key="metric">
					<th>{{ metricTitle(metric) }}</th>
					<td v-for="month of months" :key="month">{{ statistics[month][metric] }}</td>
					<td>{{ Math.round(averageForMetric(metric) * 10) / 10 }}</td>
				</tr>
			</table>

			<div class="charts-grid">
				<div v-for="metric in metrics" :key="metric" class="chart-item">
					<h2>{{ metricTitle(metric) }}</h2>
					<UsageMetricLineChart :chartData="getChartData(metric)" />
				</div>
			</div>
		</template>
		<template v-else>Loading...</template>
	</div>
</template>

<script>
import UsageMetricLineChart from "@/components/UsageMetricLineChart.vue"
import { callCondensFunction } from "@/utils"
import _ from "lodash"

export default {
	computed: {
		months() {
			return Object.keys(this.statistics)
		},
		metrics() {
			return Object.keys(Object.values(this.statistics)?.[0] ?? {})
		},
	},
	components: {
		UsageMetricLineChart,
	},
	props: {
		orgId: { type: String, required: true },
	},
	data() {
		return {
			statistics: {},
		}
	},
	async created() {
		this.statistics = await callCondensFunction(
			"internal/customerUsageMetrics",
			{ orgId: this.orgId },
			{
				pickRegionFor: this.orgId,
			}
		)
	},
	methods: {
		metricTitle(metric) {
			return _.startCase(metric)
		},
		valuesForMetric(metric) {
			return this.months.map(month => this.statistics[month][metric])
		},
		averageForMetric(metric) {
			const monthValues = this.valuesForMetric(metric).slice(0, -1)
			return _.sum(monthValues) / monthValues.length
		},
		getChartData(metric) {
			const months = this.months
			const average = this.averageForMetric(metric)
			return {
				labels: months,
				datasets: [
					{
						label: metric,
						data: this.valuesForMetric(metric),
						backgroundColor: "rgba(54, 162, 235, 0.2)",
						borderColor: "rgba(54, 162, 235, 1)",
						borderWidth: 2,
						pointRadius: 5,
						pointBackgroundColor: "rgba(54, 162, 235, 1)",
					},
					{
						label: "Average",
						data: months.map(() => average),
						backgroundColor: "transparent",
						borderColor: "rgba(255, 99, 132, 1)",
						borderWidth: 2,
						pointRadius: 0,
					},
				],
			}
		},
	},
}
</script>

<style scoped>
table {
	border-collapse: collapse;
	th {
		text-align: left;
	}
	td,
	th {
		border: 1px solid lightgray;
		padding: 3px 6px;
	}
}

.charts-grid {
	margin-top: 30px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
}

.chart-item {
	background-color: #f9f9f9;
	border-radius: 8px;
	padding: 15px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1 {
	text-align: center;
	margin-bottom: 30px;
}

h2 {
	margin-top: 0;
	margin-bottom: 15px;
	font-size: 18px;
}

@media (max-width: 1200px) {
	.charts-grid {
		grid-template-columns: 1fr;
	}
}
</style>
