<template>
	<div class="chart-container">
		<canvas ref="canvas"></canvas>
	</div>
</template>

<script>
import { Chart, registerables } from "chart.js"

Chart.register(...registerables)

export default {
	props: {
		chartData: { type: Object, required: true },
	},
	data() {
		return {
			chart: null,
		}
	},
	mounted() {
		this.renderChart()
	},
	methods: {
		renderChart() {
			const ctx = this.$refs.canvas.getContext("2d")

			if (this.chart) {
				this.chart.destroy()
			}
			this.chart = new Chart(ctx, {
				type: "line",
				data: this.chartData,
				options: {
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						y: {
							beginAtZero: true,
						},
					},
					plugins: {
						legend: {
							display: false,
							position: "top",
						},
					},
				},
			})
		},
	},
	watch: {
		chartData: {
			handler() {
				this.renderChart()
			},
			deep: true,
		},
	},
}
</script>

<style scoped>
.chart-container {
	position: relative;
	width: 100%;
	height: 300px;
	margin-bottom: 20px;
}
</style>
