// this file is (manually) synced between website and webapp

import _ from "lodash"

export const getPricingConfig = ({ allowBusinessMonthly = false } = {}) => ({
	individual_2023: {
		title: "Lite",
		description: "Streamline research analysis of individual projects",
		featureHeading: "Key features:",
		features: [
			"Unlimited automated transcription",
			"Analysis features",
			{ text: "Basic integrations", tooltip: "Zoom, Google Drive & Google Cloud Storage" },
			"Unlimited projects",
			"Unlimited read-only sharing of findings via public link",
			"Personal support and onboarding",
		],
		seatPrice: {
			usd: { yearly: 165, monthly: 15 },
			eur: { yearly: 165, monthly: 15 },
		},
		basePrice: {
			usd: { yearly: 165, monthly: 15 },
			eur: { yearly: 165, monthly: 15 },
		},
		freeMonthsWhenYearly: 1,
		baseSeats: 1,
		seatsStep: 1,
	},
	business_2025: {
		title: "Business",
		description: "Scale research across your organization",
		featureHeading: "Everything in Lite, plus:",
		features: [
			"Repository Features",
			"All integrations",
			"Unlimited read-only viewers",
			"Dedicated Stakeholder interface",
			"Data security assessments",
			"Optional: SSO and HIPAA Addon",
		],
		basePrice: {
			usd: { yearly: 6000, ...(allowBusinessMonthly ? { monthly: 500 } : {}) },
			eur: { yearly: 6000, ...(allowBusinessMonthly ? { monthly: 500 } : {}) },
		},
		seatPrice: {
			usd: { yearly: 1020, ...(allowBusinessMonthly ? { monthly: 85 } : {}) },
			eur: { yearly: 1020, ...(allowBusinessMonthly ? { monthly: 85 } : {}) },
		},
		addons: {
			sso: { usd: { yearly: 2400, monthly: 200 }, eur: { yearly: 2400, monthly: 200 }, title: "SSO" },
			hipaa: { usd: { yearly: 1200, monthly: 100 }, eur: { yearly: 1200, monthly: 100 }, title: "HIPAA" },
		},
		baseSeats: 5,
		seatsStep: 1,
	},
	enterprise_2025: {
		title: "Enterprise",
		description: "Best for organizations with custom requirements",
		featureHeading: "Everything in Business, plus:",
		features: [
			"Custom terms",
			"Service Level Agreements (SLAs)",
			"Automated data deletion",
			"Advanced user & permission management",
		],
		isOnDemandPricing: true,
		// basePrice: {
		// 	usd: { yearly: 36000 },
		// 	eur: { yearly: 36000 },
		// },
		// seatPrice: {
		// 	usd: { yearly: 1200 },
		// 	eur: { yearly: 1200 },
		// },
		baseSeats: 5,
		seatsStep: 1,
	},
	team_2023: {
		title: "Team",
		description: "Build a research repository and empower stakeholders",
		featureHeading: "Everything in Individual, plus:",
		features: [
			"Automated transcription: <br/> 50 hours per month included",
			"Repository features",
			"All integrations",
			"Unlimited read-only viewers",
			"Dedicated Stakeholder Repository",
		],
		basePrice: {
			usd: { yearly: 2090, monthly: 190 },
			eur: { yearly: 1870, monthly: 170 },
		},
		seatPrice: {
			usd: { yearly: 330, monthly: 30 },
			eur: { yearly: 308, monthly: 28 },
		},
		addons: {
			sso: { usd: { yearly: 1200, monthly: 100 }, eur: { yearly: 1200, monthly: 100 }, title: "SSO" },
		},
		freeMonthsWhenYearly: 1,
		baseSeats: 3,
		seatsStep: 1,
	},
	business_2023: {
		title: "Business",
		description: "Scale research across your organization",
		featureHeading: "Everything in Team, plus:",
		features: [
			"Automated transcription: <br/> Unlimited",
			"Stakeholder collaboration",
			"Granular permission management",
			"Single sign-on (SSO)",
			"Data security assessments",
		],
		basePrice: {
			usd: { yearly: 5400, ...(allowBusinessMonthly ? { monthly: 450 } : {}) },
			eur: { yearly: 4800, ...(allowBusinessMonthly ? { monthly: 400 } : {}) },
		},
		seatPrice: {
			usd: { yearly: 600, ...(allowBusinessMonthly ? { monthly: 50 } : {}) },
			eur: { yearly: 540, ...(allowBusinessMonthly ? { monthly: 45 } : {}) },
		},
		baseSeats: 5,
		seatsStep: 1,
	},
	enterprise_2023: {
		title: "Enterprise",
		description: "Best for organizations with custom requirements",
		featureHeading: "Everything in Business, plus:",
		features: ["Custom terms", "Service Level Agreements (SLAs)", "Custom data retention"],
		basePrice: {
			usd: { yearly: 14400 },
			eur: { yearly: 12000 },
		},
		seatPrice: {
			usd: { yearly: 840 },
			eur: { yearly: 720 },
		},
		baseSeats: 10,
		seatsStep: 1,
	},
	individual_2021: {
		title: "Individual Plus",
		description: "Best for researchers working independently",
		featureHeading: "Key features:",
		features: [
			"Automated transcription: <br/> 15 hours per month included",
			{ text: "Basic integrations", tooltip: "Zoom, Google Drive & Google Cloud Storage" },
			"Unlimited projects & file uploads",
			"Unlimited sharing of findings in read-only mode",
			"Personal support and onboarding",
		],
		seatPrice: {
			usd: { yearly: 396, monthly: 40 },
			eur: { yearly: 360, monthly: 36 },
		},
		basePrice: {
			usd: { yearly: 396, monthly: 40 },
			eur: { yearly: 360, monthly: 36 },
		},
		baseSeats: 1,
		seatsStep: 1,
	},
	company_2021: {
		title: "Company (discontinued)",
		description: "Democratize research and empower stakeholders",
		featureHeading: "Everything in Individual, plus:",
		features: [
			{
				monthly: "Automated transcription: <br/> 50 hours per month included",
				yearly: "Automated transcription: <br/> 600 hours per year included",
			},
			"All integrations",
			"Unlimited read-only access for viewers",
			"Dedicated Stakeholder Repository",
		],
		basePrice: {
			usd: { yearly: 1620, monthly: 162 },
			eur: { yearly: 1440, monthly: 144 },
		},
		seatPrice: {
			usd: { yearly: 240, monthly: 25 },
			eur: { yearly: 216, monthly: 22 },
		},
		addons: {
			sso: { usd: { yearly: 1200, monthly: 100 }, eur: { yearly: 1200, monthly: 100 } },
		},
		baseSeats: 5,
		seatsStep: 1,
	},
	enterprise_2021: {
		title: "Enterprise",
		description: "Best for organizations with custom requirements",
		featureHeading: "Everything in Company, plus:",
		features: [
			"Automated transcription: <br/> 1200 hours per year included",
			"Data security assessments",
			"Single sign-on (SSO)",
			"Data Migration Support",
			"Individual payment terms",
		],
		basePrice: {
			usd: { yearly: 7200 },
			eur: { yearly: 6000 },
		},
		seatPrice: {
			usd: { yearly: 720 },
			eur: { yearly: 600 },
		},
		baseSeats: 10,
		seatsStep: 1,
	},
	enterprise_2020: {
		title: "Enterprise",
		description: "Best for organizations with custom requirements",
		featureHeading: "Everything in Company, plus:",
		features: [
			"Automated transcription: <br/> 1200 hours per year included",
			"Data security assessments",
			"Single sign-on (SSO)",
			"Data Migration Support",
			"Individual payment terms",
		],
		basePrice: {
			usd: { yearly: 4800 },
			eur: { yearly: 4800 },
		},
		seatPrice: {
			usd: { yearly: 480 },
			eur: { yearly: 480 },
		},
		baseSeats: 10,
		seatsStep: 1,
	},
})

export const pricingConfig = getPricingConfig()
export const calculatePrice = (
	plan,
	currency,
	billingInterval,
	seats,
	addons = [],
	pricingConfigParams = undefined
) => {
	const config = getPricingConfig(pricingConfigParams)[plan]
	if (config.isOnDemandPricing) {
		return null
	}
	const basePrice = config.basePrice[currency][billingInterval]
	const seatPrice = config.seatPrice[currency][billingInterval]
	if (basePrice == null || seatPrice == null) {
		return null
	}
	seats = Math.max(seats, config.baseSeats)
	let price = basePrice + (seats - config.baseSeats) * seatPrice
	for (const addon of addons ?? []) {
		price += config.addons?.[addon]?.[currency]?.[billingInterval] ?? 0
	}
	return price
}
